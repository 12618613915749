export const objToDate = (obj: any, formatResult?: boolean) => {
  let result = new Date(0);
  result.setSeconds(obj.seconds);
  result.setMilliseconds(obj.nanoseconds / 1000000);
  return formatResult ? result.toLocaleDateString() : result;
};

export const validatePhone = (
  phoneNumber: string,
  returnPhoneNumber: boolean,
  cb?: any,
  errorCb?: any
) => {
  // regex will match any 10 digit number with or without dashes, parentheses, or spaces
  let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (
    phoneNumber !== undefined &&
    (phoneNumber as string).match(regex) &&
    // checks phone number length to make sure it's 10 digits
    phoneNumber.replace(/[^\d]/g, "").length === 10
  ) {
    let formattedPhoneNumber = parseInt(phoneNumber.replace(/[^\d]/g, ""));
    cb && cb();
    if (returnPhoneNumber) return formattedPhoneNumber;
  } else {
    errorCb && errorCb();
  }
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const CHRISTIAN = {
  ABORTIFACIENTS: -3,
  ABORTION_PROMOTION: -2,
  ABORTION_SERVICES: -3,
  ACCESS_AND_AFFORDABILITY_BIC: 1,
  AIR_QUALITY_BIC: 1,
  ALCOHOL: -1,
  ALCOHOL_5PERCENT_REVENUE: 0,
  BUSINESS_ETHICS_BIC: 3,
  BUSINESS_MODEL_RESILIENCE_BIC: 0,
  CANNABIS_CULTIVATION_PROCESSING: -1,
  CANNABIS_RETAIL_THC: -1,
  COMPETITIVE_BEHAVIOR_BIC: 1,
  CONTRACEPTIVES_BARRIER: 0,
  CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC: 1,
  CUSTOMER_PRIVACY_BIC: 0,
  CUSTOMER_WELFARE_BIC: 3,
  DATA_SECURITY_BIC: 0,
  ECOLOGICAL_IMPACTS_BIC: 1,
  EMBRYONIC_STEM_CELL: -3,
  EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC: 1,
  EMPLOYEE_HEALTH_AND_SAFETY_BIC: 3,
  ENERGY_MANAGEMENT_BIC: 0,
  EXPLOITATION: -3,
  GAMBLING: -1,
  GAMBLING_5PERCENT_REVENUE: 0,
  GHG_EMISSIONS_BIC: 1,
  HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC: 3,
  INVITRO_FERTILIZATION: -2,
  LABOR_PRACTICES_BIC: 3,
  LGBTA_PROMOTION: -2,
  MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC: 0,
  MATERIALS_SOURCING_AND_EFFICIENCY_BIC: 0,
  PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC: 0,
  PORNOGRAPHY: -3,
  PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC: 0,
  PRODUCT_QUALITY_AND_SAFETY_BIC: 1,
  SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC: 2,
  STATE_OWNED_ENTERPRISE: -2,
  SUPPLY_CHAIN_MANAGEMENT_BIC: 1,
  SYSTEMIC_RISK_MANAGEMENT_BIC: 1,
  TOBACCO: -1,
  TOBACCO_5PERCENT_REVENUE: 0,
  WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC: 1,
  WATER_AND_WASTEWATER_MANAGEMENT_BIC: 1,
  // WEAPONS_COMPONENTS: 0,
  WEAPONS_COMPONENTS_5PERCENT_REVENUE: 0,
  WEAPONS_MILITARY: 0,
};

export const CATHOLIC = {
  ABORTIFACIENTS: -3,
  ABORTION_PROMOTION: -2,
  ABORTION_SERVICES: -3,
  ACCESS_AND_AFFORDABILITY_BIC: 1,
  AIR_QUALITY_BIC: 1,
  ALCOHOL: 0,
  ALCOHOL_5PERCENT_REVENUE: 0,
  BUSINESS_ETHICS_BIC: 3,
  BUSINESS_MODEL_RESILIENCE_BIC: 0,
  CANNABIS_CULTIVATION_PROCESSING: -1,
  CANNABIS_RETAIL_THC: -1,
  COMPETITIVE_BEHAVIOR_BIC: 1,
  CONTRACEPTIVES_BARRIER: -2,
  CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC: 1,
  CUSTOMER_PRIVACY_BIC: 0,
  CUSTOMER_WELFARE_BIC: 3,
  DATA_SECURITY_BIC: 0,
  ECOLOGICAL_IMPACTS_BIC: 1,
  EMBRYONIC_STEM_CELL: -3,
  EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC: 1,
  EMPLOYEE_HEALTH_AND_SAFETY_BIC: 3,
  ENERGY_MANAGEMENT_BIC: 1,
  EXPLOITATION: -3,
  GAMBLING: -1,
  GAMBLING_5PERCENT_REVENUE: 0,
  GHG_EMISSIONS_BIC: 3,
  HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC: 3,
  INVITRO_FERTILIZATION: -2,
  LABOR_PRACTICES_BIC: 3,
  LGBTA_PROMOTION: 0,
  MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC: 0,
  MATERIALS_SOURCING_AND_EFFICIENCY_BIC: 0,
  PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC: 2,
  PORNOGRAPHY: -3,
  PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC: 0,
  PRODUCT_QUALITY_AND_SAFETY_BIC: 1,
  SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC: 2,
  STATE_OWNED_ENTERPRISE: -2,
  SUPPLY_CHAIN_MANAGEMENT_BIC: 2,
  SYSTEMIC_RISK_MANAGEMENT_BIC: 1,
  TOBACCO: -1,
  TOBACCO_5PERCENT_REVENUE: 0,
  WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC: 2,
  WATER_AND_WASTEWATER_MANAGEMENT_BIC: 2,
  WEAPONS_COMPONENTS_5PERCENT_REVENUE: 0,
  WEAPONS_MILITARY: -2,
};

// export const PROGRESSIVE = {
//   ABORTIFACIENTS: 3,
//   ABORTION_PROMOTION: 3,
//   ABORTION_SERVICES: 3,
//   ACCESS_AND_AFFORDABILITY_BIC: 3,
//   AIR_QUALITY_BIC: 3,
//   ALCOHOL: 0,
//   ALCOHOL_5PERCENT_REVENUE: 0,
//   BUSINESS_ETHICS_BIC: 2,
//   BUSINESS_MODEL_RESILIENCE_BIC: 0,
//   CANNABIS_CULTIVATION_PROCESSING: 1,
//   CANNABIS_RETAIL_THC: 1,
//   COMPETITIVE_BEHAVIOR_BIC: 0,
//   CONTRACEPTIVES_BARRIER: 0,
//   CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC: 2,
//   CUSTOMER_PRIVACY_BIC: 2,
//   CUSTOMER_WELFARE_BIC: 3,
//   DATA_SECURITY_BIC: 2,
//   ECOLOGICAL_IMPACTS_BIC: 3,
//   EMBRYONIC_STEM_CELL: 3,
//   EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC: 3,
//   EMPLOYEE_HEALTH_AND_SAFETY_BIC: 3,
//   ENERGY_MANAGEMENT_BIC: 2,
//   EXPLOITATION: -3,
//   GAMBLING: 0,
//   GAMBLING_5PERCENT_REVENUE: 0,
//   GHG_EMISSIONS_BIC: 3,
//   HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC: 3,
//   INVITRO_FERTILIZATION: 2,
//   LABOR_PRACTICES_BIC: 3,
//   LGBTA_PROMOTION: 3,
//   MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC: 1,
//   MATERIALS_SOURCING_AND_EFFICIENCY_BIC: 1,
//   PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC: 3,
//   PORNOGRAPHY: 0,
//   PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC: 1,
//   PRODUCT_QUALITY_AND_SAFETY_BIC: 2,
//   SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC: 2,
//   STATE_OWNED_ENTERPRISE: 0,
//   SUPPLY_CHAIN_MANAGEMENT_BIC: 2,
//   SYSTEMIC_RISK_MANAGEMENT_BIC: 2,
//   TOBACCO: -2,
//   TOBACCO_5PERCENT_REVENUE: 0,
//   WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC: 3,
//   WATER_AND_WASTEWATER_MANAGEMENT_BIC: 3,
//   WEAPONS_COMPONENTS: -2,
//   WEAPONS_COMPONENTS_5PERCENT_REVENUE: 0,
//   WEAPONS_MILITARY: -3,
// }

export const ENVIRONMENTAL = {
  ABORTIFACIENTS: 0,
  ABORTION_PROMOTION: 0,
  ABORTION_SERVICES: 0,
  ACCESS_AND_AFFORDABILITY_BIC: 0,
  AIR_QUALITY_BIC: 3,
  ALCOHOL: 0,
  ALCOHOL_5PERCENT_REVENUE: 0,
  BUSINESS_ETHICS_BIC: 0,
  BUSINESS_MODEL_RESILIENCE_BIC: 0,
  CANNABIS_CULTIVATION_PROCESSING: 0,
  CANNABIS_RETAIL_THC: 0,
  COMPETITIVE_BEHAVIOR_BIC: 0,
  CONTRACEPTIVES_BARRIER: 0,
  CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC: 2,
  CUSTOMER_PRIVACY_BIC: 0,
  CUSTOMER_WELFARE_BIC: 0,
  DATA_SECURITY_BIC: 0,
  ECOLOGICAL_IMPACTS_BIC: 3,
  EMBRYONIC_STEM_CELL: 0,
  EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC: 0,
  EMPLOYEE_HEALTH_AND_SAFETY_BIC: 2,
  ENERGY_MANAGEMENT_BIC: 2,
  EXPLOITATION: 0,
  GAMBLING: 0,
  GAMBLING_5PERCENT_REVENUE: 0,
  GHG_EMISSIONS_BIC: 3,
  HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC: 0,
  INVITRO_FERTILIZATION: 0,
  LABOR_PRACTICES_BIC: 0,
  LGBTA_PROMOTION: 0,
  MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC: 1,
  MATERIALS_SOURCING_AND_EFFICIENCY_BIC: 1,
  PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC: 3,
  PORNOGRAPHY: 0,
  PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC: 1,
  PRODUCT_QUALITY_AND_SAFETY_BIC: 0,
  SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC: 0,
  STATE_OWNED_ENTERPRISE: 0,
  SUPPLY_CHAIN_MANAGEMENT_BIC: 0,
  SYSTEMIC_RISK_MANAGEMENT_BIC: 2,
  TOBACCO: 0,
  TOBACCO_5PERCENT_REVENUE: 0,
  WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC: 3,
  WATER_AND_WASTEWATER_MANAGEMENT_BIC: 3,
  // WEAPONS_COMPONENTS: 0,
  WEAPONS_COMPONENTS_5PERCENT_REVENUE: 0,
  WEAPONS_MILITARY: 0,
};

export const CONSERVATIVE = {
  ABORTIFACIENTS: -3,
  ABORTION_PROMOTION: -2,
  ABORTION_SERVICES: -3,
  ACCESS_AND_AFFORDABILITY_BIC: 0,
  AIR_QUALITY_BIC: 0,
  ALCOHOL: -1,
  BUSINESS_ETHICS_BIC: 3,
  BUSINESS_MODEL_RESILIENCE_BIC: 1,
  CANNABIS_CULTIVATION_PROCESSING: -1,
  CANNABIS_RETAIL_THC: -1,
  COMPETITIVE_BEHAVIOR_BIC: 3,
  CONTRACEPTIVES_BARRIER: 0,
  CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC: 1,
  CUSTOMER_PRIVACY_BIC: 3,
  CUSTOMER_WELFARE_BIC: 2,
  DATA_SECURITY_BIC: 3,
  ECOLOGICAL_IMPACTS_BIC: 0,
  EMBRYONIC_STEM_CELL: -3,
  EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC: 0,
  EMPLOYEE_HEALTH_AND_SAFETY_BIC: 1,
  ENERGY_MANAGEMENT_BIC: 0,
  EXPLOITATION: -3,
  GAMBLING: 0,
  GHG_EMISSIONS_BIC: 0,
  HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC: 2,
  INVITRO_FERTILIZATION: 0,
  LABOR_PRACTICES_BIC: 1,
  LGBTA_PROMOTION: -2,
  MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC: 0,
  MATERIALS_SOURCING_AND_EFFICIENCY_BIC: 1,
  PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC: 0,
  PORNOGRAPHY: -1,
  PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC: 0,
  PRODUCT_QUALITY_AND_SAFETY_BIC: 1,
  SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC: 1,
  STATE_OWNED_ENTERPRISE: -3,
  SUPPLY_CHAIN_MANAGEMENT_BIC: 1,
  SYSTEMIC_RISK_MANAGEMENT_BIC: 1,
  TOBACCO: 0,
  WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC: 1,
  WATER_AND_WASTEWATER_MANAGEMENT_BIC: 1,
  // WEAPONS_COMPONENTS: 2,
  WEAPONS_MILITARY: 3,
};

export const LIBERTARIAN = {
  ABORTIFACIENTS: 0,
  ABORTION_PROMOTION: 0,
  ABORTION_SERVICES: 0,
  ACCESS_AND_AFFORDABILITY_BIC: 0,
  AIR_QUALITY_BIC: 0,
  ALCOHOL: 0,
  BUSINESS_ETHICS_BIC: 3,
  BUSINESS_MODEL_RESILIENCE_BIC: 3,
  CANNABIS_CULTIVATION_PROCESSING: 1,
  CANNABIS_RETAIL_THC: 1,
  COMPETITIVE_BEHAVIOR_BIC: 3,
  CONTRACEPTIVES_BARRIER: 0,
  CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC: 0,
  CUSTOMER_PRIVACY_BIC: 3,
  CUSTOMER_WELFARE_BIC: 0,
  DATA_SECURITY_BIC: 3,
  ECOLOGICAL_IMPACTS_BIC: 0,
  EMBRYONIC_STEM_CELL: 0,
  EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC: 0,
  EMPLOYEE_HEALTH_AND_SAFETY_BIC: 0,
  ENERGY_MANAGEMENT_BIC: 0,
  EXPLOITATION: -3,
  GAMBLING: 0,
  GHG_EMISSIONS_BIC: 0,
  HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC: 0,
  INVITRO_FERTILIZATION: 0,
  LABOR_PRACTICES_BIC: 0,
  LGBTA_PROMOTION: 0,
  MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC: 0,
  MATERIALS_SOURCING_AND_EFFICIENCY_BIC: 0,
  PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC: 0,
  PORNOGRAPHY: 0,
  PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC: 0,
  PRODUCT_QUALITY_AND_SAFETY_BIC: 0,
  SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC: 0,
  STATE_OWNED_ENTERPRISE: -3,
  SUPPLY_CHAIN_MANAGEMENT_BIC: 0,
  SYSTEMIC_RISK_MANAGEMENT_BIC: 0,
  TOBACCO: 0,
  WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC: 0,
  WATER_AND_WASTEWATER_MANAGEMENT_BIC: 0,
  // WEAPONS_COMPONENTS: 3,
  WEAPONS_MILITARY: 3,
};

export const ESG = {
  ABORTIFACIENTS: 0,
  ABORTION_PROMOTION: 0,
  ABORTION_SERVICES: 0,
  ACCESS_AND_AFFORDABILITY_BIC: 1,
  AIR_QUALITY_BIC: 1,
  ALCOHOL: 0,
  BUSINESS_ETHICS_BIC: 1,
  BUSINESS_MODEL_RESILIENCE_BIC: 1,
  CANNABIS_CULTIVATION_PROCESSING: 0,
  CANNABIS_RETAIL_THC: 0,
  COMPETITIVE_BEHAVIOR_BIC: 1,
  CONTRACEPTIVES_BARRIER: 0,
  CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC: 1,
  CUSTOMER_PRIVACY_BIC: 1,
  CUSTOMER_WELFARE_BIC: 1,
  DATA_SECURITY_BIC: 1,
  ECOLOGICAL_IMPACTS_BIC: 1,
  EMBRYONIC_STEM_CELL: 0,
  EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC: 1,
  EMPLOYEE_HEALTH_AND_SAFETY_BIC: 1,
  ENERGY_MANAGEMENT_BIC: 1,
  EXPLOITATION: 0,
  GAMBLING: 0,
  GHG_EMISSIONS_BIC: 1,
  HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC: 1,
  INVITRO_FERTILIZATION: 0,
  LABOR_PRACTICES_BIC: 1,
  LGBTA_PROMOTION: 0,
  MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC: 1,
  MATERIALS_SOURCING_AND_EFFICIENCY_BIC: 1,
  PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC: 1,
  PORNOGRAPHY: 0,
  PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC: 1,
  PRODUCT_QUALITY_AND_SAFETY_BIC: 1,
  SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC: 1,
  STATE_OWNED_ENTERPRISE: 0,
  SUPPLY_CHAIN_MANAGEMENT_BIC: 1,
  SYSTEMIC_RISK_MANAGEMENT_BIC: 1,
  TOBACCO: 0,
  WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC: 1,
  WATER_AND_WASTEWATER_MANAGEMENT_BIC: 1,
  // WEAPONS_COMPONENTS: 0,
  WEAPONS_MILITARY: 0,
};

export const EMPTY = {
  ABORTIFACIENTS: 0,
  ABORTION_PROMOTION: 0,
  ABORTION_SERVICES: 0,
  ACCESS_AND_AFFORDABILITY_BIC: 0,
  AIR_QUALITY_BIC: 0,
  ALCOHOL: 0,
  BUSINESS_ETHICS_BIC: 0,
  BUSINESS_MODEL_RESILIENCE_BIC: 0,
  CANNABIS_CULTIVATION_PROCESSING: 0,
  CANNABIS_RETAIL_THC: 0,
  COMPETITIVE_BEHAVIOR_BIC: 0,
  CONTRACEPTIVES_BARRIER: 0,
  CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC: 0,
  CUSTOMER_PRIVACY_BIC: 0,
  CUSTOMER_WELFARE_BIC: 0,
  DATA_SECURITY_BIC: 0,
  ECOLOGICAL_IMPACTS_BIC: 0,
  EMBRYONIC_STEM_CELL: 0,
  EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC: 0,
  EMPLOYEE_HEALTH_AND_SAFETY_BIC: 0,
  ENERGY_MANAGEMENT_BIC: 0,
  EXPLOITATION: 0,
  GAMBLING: 0,
  GHG_EMISSIONS_BIC: 0,
  HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC: 0,
  INVITRO_FERTILIZATION: 0,
  LABOR_PRACTICES_BIC: 0,
  LGBTA_PROMOTION: 0,
  MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC: 0,
  MATERIALS_SOURCING_AND_EFFICIENCY_BIC: 0,
  PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC: 0,
  PORNOGRAPHY: 0,
  PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC: 0,
  PRODUCT_QUALITY_AND_SAFETY_BIC: 0,
  SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC: 0,
  STATE_OWNED_ENTERPRISE: 0,
  SUPPLY_CHAIN_MANAGEMENT_BIC: 0,
  SYSTEMIC_RISK_MANAGEMENT_BIC: 0,
  TOBACCO: 0,
  WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC: 0,
  WATER_AND_WASTEWATER_MANAGEMENT_BIC: 0,
  WEAPONS_COMPONENTS: 0,
  WEAPONS_MILITARY: 0,
};

const PREBUILT_PLAYBOOKS: any = {
  christian: CHRISTIAN,
  catholic: CATHOLIC,
  // progressive: PROGRESSIVE,
  environmental: ENVIRONMENTAL,
  empty: EMPTY,
  // conservative: CONSERVATIVE,
  // libertarian: LIBERTARIAN,
  // esg: ESG,
};

export const PLAYBOOK_DEFINITIONS = {
  ABORTIFACIENTS:
    "Company produces abortifacient drugs. This category includes all pharmaceuticals used to terminate a pregnancy anytime from the moment of conception onward.",
  ABORTION_PROMOTION:
    "Companies that promote abortion access through legislative support, corporate philanthropic activity, and/or employee travel benefits.",
  ABORTION_SERVICES:
    "Company provides or facilitates procedures that terminate a pregnancy.",
  ACCESS_AND_AFFORDABILITY_BIC:
    "The category addresses a company’s ability to ensure broad access to its products and services, specifically in the context of underserved markets and/or population groups. It includes the management of issues related to universal needs, such as the accessibility and affordability of health care, financial services, utilities, education, and telecommunications.",
  AIR_QUALITY_BIC:
    "The category addresses the management of air quality impacts resulting from stationary (e.g., factories, power plants) and mobile sources (e.g., trucks, delivery vehicles, planes) as well as industrial emissions. Relevant airborne pollutants include, but are not limited to, oxides of nitrogen (NOx), oxides of sulfur (SOx), volatile organic compounds (VOCs), heavy metals, particulate matter, and chlorofluorocarbons. The category does not include GHG emissions, which are addressed in a separate category.",
  ALCOHOL:
    "Companies that have a primary purpose of generating revenue from the sale, production, or distribution of alcohol.",
  BUSINESS_ETHICS_BIC:
    "The category addresses the company’s approach to managing risks and opportunities surrounding ethical conduct of business, including fraud, corruption, bribery and facilitation payments, fiduciary responsibilities, and other behavior that may have an ethical component. This includes sensitivity to business norms and standards as they shift over time, jurisdiction, and culture. It addresses the company’s ability to provide services that satisfy the highest professional and ethical standards of the industry, which means to avoid conflicts of interest, misrepresentation, bias, and negligence through training employees adequately and implementing policies and procedures to ensure employees provide services free from bias and error.",
  BUSINESS_MODEL_RESILIENCE_BIC:
    "The category addresses an industry’s capacity to manage risks and opportunities associated with incorporating social, environmental, and political transitions into long-term business model planning. This includes responsiveness to the transition to a low-carbon and climate-constrained economy, as well as growth and creation of new markets among unserved and underserved socio-economic populations. The category highlights industries in which evolving environmental and social realities may challenge companies to fundamentally adapt or may put their business models at risk.",
  CANNABIS_CULTIVATION_PROCESSING:
    "Cultivates or processes cannabis for retail or wholesale distribution.",
  CANNABIS_RETAIL_THC:
    "Produces or distributes retail cannabis products containing THC, which is the psychoactive component of cannabis.",
  COMPETITIVE_BEHAVIOR_BIC:
    "The category covers social issues associated with existence of monopolies, which may include, but are not limited to, excessive prices, poor quality of service, and inefficiencies. It addresses a company's management of legal and social expectation around monopolistic and anti-competitive practices, including issues related to bargaining power, collusion, price fixing or manipulation, and protection of patents and intellectual property (IP).",
  CONTRACEPTIVES_BARRIER:
    "This category includes barrier-type contraceptives such as condoms and diaphragms, which prevent pregnancy by creating a physical barrier rather than hormonal or chemical means.",
  CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC:
    "The category addresses the company's use of management systems and scenario planning to identify, understand, and prevent or minimize the occurrence of low-probability, high-impact accidents and emergencies with significant potential environmental and social externalities. It relates to the culture of safety at a company, its relevant safety management systems and technological controls, the potential human, environmental, and social implications of such events occurring, and the long-term effects to an organization, its workers, and society should these events occur.",
  CUSTOMER_PRIVACY_BIC:
    "The category addresses management of risks related to the use of personally identifiable information (PII) and other customer or user data for secondary purposes including but not limited to marketing through affiliates and non-affiliates. The scope of the category includes social issues that may arise from a company's approach to collecting data, obtaining consent (e.g., opt-in policies), managing user and customer expectations regarding how their data is used, and managing evolving regulation. It excludes social issues arising from cybersecurity risks, which are covered in a separate category.",
  CUSTOMER_WELFARE_BIC:
    "The category addresses customer welfare concerns over issues including, but not limited to, health and nutrition of foods and beverages, antibiotic use in animal production, and management of controlled substances. The category addresses the company's ability to provide consumers with manufactured products and services that are aligned with societal expectations. It does not include issues directly related to quality and safety malfunctions of manufactured products and services, but instead addresses qualities inherent to the design and delivery of products and services where customer welfare may be in question. The scope of the category also captures companies' ability to prevent counterfeit products.",
  DATA_SECURITY_BIC:
    "The category addresses management of risks related to collection, retention, and use of sensitive, confidential, and/or proprietary customer or user data. It includes social issues that may arise from incidents such as data breaches in which personally identifiable information (PII) and other user or customer data may be exposed. It addresses a company's strategy, policies, and practices related to IT infrastructure, staff training, record keeping, cooperation with law enforcement, and other mechanisms used to ensure security of customer or user data.",
  ECOLOGICAL_IMPACTS_BIC:
    "The category addresses management of the company's impacts on ecosystems and biodiversity through activities including, but not limited to, land use for exploration, natural resource extraction, and cultivation, as well as project development, construction, and siting. The impacts include, but are not limited to, biodiversity loss, habitat destruction, and deforestation at all stages, planning, land acquisition, permitting, development, operations, and site remediation. The category does not cover impacts of climate change on ecosystems and biodiversity.",
  EMBRYONIC_STEM_CELL:
    "Company is engaged in distribution of stem cells. This category includes companies which perform research on or produce products using embryonic stem cells, companies which provide embryonic stem cells to other entities and companies which utilize propagated stem cell lines which originally derived from embryonic stem cells.",
  EMBRYONIC_STEM_CELL_DISTRIBUTING:
    "Company is engaged directly or indirectly in distribution of stem cells. This category includes companies which perform research on or produce products using embryonic stem cells, companies which provide embryonic stem cells to other entities and companies which utilize propagated stem cell lines which originally derived from embryonic stem cells.",
  EMBRYONIC_STEM_PRODUCTION:
    "Company is engaged directly or indirectly in embryonic stem cell production. This category includes companies which perform research on or produce products using embryonic stem cells, companies which provide embryonic stem cells to other entities and companies which utilize propagated stem cell lines which originally derived from embryonic stem cells.",
  EMBRYONIC_STEM_RESEARCH:
    "Company is engaged directly or indirectly in embryonic stem cell research. This category includes companies which perform research on or produce products using embryonic stem cells, companies which provide embryonic stem cells to other entities and companies which utilize propagated stem cell lines which originally derived from embryonic stem cells.",
  EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC:
    "The category addresses a company's ability to ensure that its hiring and promotion practices embrace the makeup of local talent pools and its customer base. It addresses the issues of discriminatory practices on the basis of race, gender, ethnicity, religion, and other factors.",
  EMPLOYEE_HEALTH_AND_SAFETY_BIC:
    "The category addresses a company's ability to create and maintain a safe and healthy workplace environment that is free of injuries, fatalities, and illness (both chronic and acute). It is traditionally accomplished through implementing safety management plans, developing training requirements for employees and contractors, and conducting regular audits of their own practices as well as those of their subcontractors. The category further captures how companies ensure physical and mental health of workforce through technology, training, corporate culture, regulatory compliance, monitoring and testing, and personal protective equipment.",
  ENERGY_MANAGEMENT_BIC:
    "The category addresses environmental impacts associated with energy consumption. It addresses the company's management of energy in manufacturing and/or for provision of products and services derived from utility providers (grid energy) not owned or controlled by the company. More specifically, it includes management of energy efficiency and intensity, energy mix, as well as grid reliance. Upstream (e.g., suppliers) and downstream (e.g., product use) energy use is not included in the scope.",
  EXPLOITATION:
    "Companies that contriubte towards the unlawful and immoral practices of exploiting individuals for the purpose of sexual or labor practices.",
  GAMBLING:
    "Company generates revenue from gambling. This category includes the operation of casinos or other gambling facilities, as well as manufacturing gambling machinery and or other gambling specific equipment.",
  GAMBLING_5PERCENT_REVENUE: 0,
  GHG_EMISSIONS_BIC:
    "The category addresses direct (Scope 1) greenhouse gas (GHG) emissions that a company generates through its operations. This includes GHG emissions from stationary (e.g., factories, power plants) and mobile sources (e.g., trucks, delivery vehicles, planes), whether a result of combustion of fuel or non-combusted direct releases during activities such as natural resource extraction, power generation, land use, or biogenic processes. The category further includes management of regulatory risks, environmental compliance, and reputational risks and opportunities, as they related to direct GHG emissions. The seven GHGs covered under the Kyoto Protocol are included within the category'carbon dioxide (CO2), methane (CH4), nitrous oxide (N2O), hydrofluorocarbons (HFCs), perfluorocarbons (PFCs), sulfur hexafluoride (SF6), and nitrogen trifluoride (NF3).",
  HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC:
    "The category addresses management of the relationship between businesses and the communities in which they operate, including, but not limited to, management of direct and indirect impacts on core human rights and the treatment of indigenous peoples. More specifically, such management may cover socio-economic community impacts, community engagement, environmental justice, cultivation of local workforces, impact on local businesses, license to operate, and environmental/social impact assessments. The category does not include environmental impacts such as air pollution or waste which, although they may impact the health and safety of members of local communities, are addressed in separate categories.",
  INVITRO_FERTILIZATION:
    "Companies that offer In Vitro Fertilization services or manufacture equipment to aid in procedures.",
  LABOR_PRACTICES_BIC:
    "The category addresses the company's ability to uphold commonly accepted labor standards in the workplace, including compliance with labor laws and internationally accepted norms and standards. This includes, but is not limited to, ensuring basic human rights related to child labor, forced or bonded labor, exploitative labor, fair wages and overtime pay, and other basic workers' rights. It also includes minimum wage policies and provision of benefits, which may influence how a workforce is attracted, retained, and motivated. The category further addresses a company's relationship with organized labor and freedom of association.",
  LGBTA_PROMOTION:
    "Companies earning an above-average rating according to an annual self-reported survey conducted by a national LGBT advocacy organization, which rates companies based on their corporate LGBT activism across several areas, including philanthropy, corporate policy, marketing efforts, and legislative support. The average score is calculated from the scores of the Fortune 500 companies that participated in the annual survey.",
  MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC:
    "The category addresses a company's approach to engaging with regulators in cases where conflicting corporate and public interests may have the potential for long-term adverse direct or indirect environmental and social impacts. The category addresses a company's level of reliance upon regulatory policy or monetary incentives (such as subsidies and taxes), actions to influence industry policy (such as through lobbying), overall reliance on a favorable regulatory environment for business competitiveness, and ability to comply with relevant regulations. It may relate to the alignment of management and investor views of regulatory engagement and compliance at large.",
  MATERIALS_SOURCING_AND_EFFICIENCY_BIC:
    "The category addresses issues related to the resilience of materials supply chains to impacts of climate change and other external environmental and social factors. It captures the impacts of such external factors on operational activity of suppliers, which can further affect availability and pricing of key resources. It addresses a company's ability to manage these risks through product design, manufacturing, and end-of-life management, such as by using of recycled and renewable materials, reducing the use of key materials (dematerialization), maximizing resource efficiency in manufacturing, and making R&D investments in substitute materials. Additionally, companies can manage these issues by screening, selection, monitoring, and engagement with suppliers to ensure their resilience to external risks. It does not address issues associated with environmental and social externalities created by operational activity of individual suppliers, which is covered in a separate category.",
  PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC:
    "The category addresses the company's ability to manage risks and opportunities associated with direct exposure of its owned or controlled assets and operations to actual or potential physical impacts of climate change. It captures environmental and social issues that may arise from operational disruptions due to physical impacts of climate change. It further captures socio-economic issues resulting from companies failing to incorporate climate change consideration in products and services sold, such as insurance policies and mortgages. The category relates to the company's ability to adapt to increased frequency and severity of extreme weather, shifting climate, sea level risk, and other expected physical impacts of climate change. Management may involve enhancing resiliency of physical assets and/or surrounding infrastructure as well as incorporation of climate change-related considerations into key business activities (e.g., mortgage and insurance underwriting, planning and development of real estate projects).",
  PORNOGRAPHY:
    "Company produces or distributes pornography. This category includes all media types, such as film, print and online. Also included are companies that produce AO (Adult Only) rated video games which contain pornographic content.",
  PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC:
    "The category addresses the characteristics of products and services provided or sold by the company. It includes, but is not limited to, managing the lifecycle impacts of products and services, such as those related to packaging, distribution, use-phase resource intensity, and other environmental and social externalities that may occur during their use-phase or at the end of life. The category captures a company's ability to address customer and societal demand for more sustainable products and services as well as to meet evolving environmental and social regulation. It does not address direct environmental or social impacts of the company's operations nor does it address health and safety risks to consumers from product use, which are covered in other categories.",
  PRODUCT_QUALITY_AND_SAFETY_BIC:
    "The category addresses issues involving unintended characteristics of products sold or services provided that may create health or safety risks to end-users. It addresses a company's ability to offer manufactured products and/or services that meet customer expectations with respect to their health and safety characteristics. It includes, but is not limited to, issues involving liability, management of recalls and market withdrawals, product testing, and chemicals/content/ingredient management in products.",
  SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC:
    "The category addresses social issues that may arise from a failure to manage the transparency, accuracy, and comprehensibility of marketing statements, advertising, and labeling of products and services. It includes, but is not limited to, advertising standards and regulations, ethical and responsible marketing practices, misleading or deceptive labeling, as well as discriminatory or predatory selling and lending practices. This may include deceptive or aggressive selling practices in which incentive structures for employees could encourage the sale of products or services that are not in the best interest of customers or clients.",
  STATE_OWNED_ENTERPRISE:
    "Company is owned and controlled by a Nation State/government, including situations where the State has veto power, or a 'golden share' is owned by the State or State controlled agency",
  SUPPLY_CHAIN_MANAGEMENT_BIC:
    "The category addresses management of environmental, social, and governance (ESG) risks within a company's supply chain. It addresses issues associated with environmental and social externalities created by suppliers through their operational activities. Such issues include, but are not limited to, environmental responsibility, human rights, labor practices, and ethics and corruption. Management may involve screening, selection, monitoring, and engagement with suppliers on their environmental and social impacts. The category does not address the impacts of external factors, such as climate change and other environmental and social factors, on suppliers, operations and/or on the availability and pricing of key resources, which is covered in a separate category.",
  SYSTEMIC_RISK_MANAGEMENT_BIC:
    "The category addresses the company's contributions to or management of systemic risks resulting from large-scale weakening or collapse of systems upon which the economy and society depend. This includes financial systems, natural resource systems, and technological systems. It addresses the mechanisms a company has in place to reduce its contributions to systemic risks and to improve safeguards that may mitigate the impacts of systemic failure. For financial institutions, the category also captures the company's ability to absorb shocks arising from financial and economic stress and meet stricter regulatory requirements related to the complexity and interconnectedness of companies in the industry.",
  TOBACCO:
    "Companies that have a primary purpose of generating revenue from growing, manufacturing or distributing tobacco products.",
  TOBACCO_5PERCENT_REVENUE: 0,
  WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC:
    "The category addresses environmental issues associated with hazardous and non-hazardous waste generated by companies. It addresses a company's management of solid wastes in manufacturing, agriculture, and other industrial processes. It covers treatment, handling, storage, disposal, and regulatory compliance. The category does not cover emissions to air or wastewater nor does it cover waste from end-of-life of products, which are addressed in separate categories.",
  WATER_AND_WASTEWATER_MANAGEMENT_BIC:
    "The category addresses a company's water use, water consumption, wastewater generation, and other impacts of operations on water resources, which may be influenced by regional differences in the availability and quality of and competition for water resources. More specifically, it addresses management strategies including, but not limited to, water efficiency, intensity, and recycling. Lastly, the category also addresses management of wastewater treatment and discharge, including groundwater and aquifer pollution.",
  // WEAPONS_COMPONENTS:
  //   "Manufactures components used in military weapons or manufactures firearms for civilian use.",
  // WEAPONS_COMPONENTS_5PERCENT_REVENUE: 0,
  WEAPONS_MILITARY: "Manufactures weapons for military use.",
};

export default PREBUILT_PLAYBOOKS;
